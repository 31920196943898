html, body {
    height: 100%;
    min-height: 100%;
}

body{
    margin: 0;
    padding: 0;
    font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@font-face {
	font-family: 'Segoe UI';
	src: url(/static/media/SegoeUI-Light.e1fb2c57.eot);
	src: local('Segoe UI Light'), local('SegoeUI-Light'),
		url(/static/media/SegoeUI-Light.e1fb2c57.eot?#iefix) format('embedded-opentype'),
		url(/static/media/SegoeUI-Light.637425dd.woff) format('woff'),
		url(/static/media/SegoeUI-Light.e936b0cd.ttf) format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Segoe UI';
	src: url(/static/media/SegoeUI-BoldItalic.0af1e09d.eot);
	src: local('Segoe UI Bold Italic'), local('SegoeUI-BoldItalic'),
		url(/static/media/SegoeUI-BoldItalic.0af1e09d.eot?#iefix) format('embedded-opentype'),
		url(/static/media/SegoeUI-BoldItalic.f130f88a.woff) format('woff'),
		url(/static/media/SegoeUI-BoldItalic.4a6837b6.ttf) format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Segoe UI';
	src: url(/static/media/SegoeUI-SemiBold.3a911b2e.eot);
	src: local('Segoe UI Semibold'), local('SegoeUI-SemiBold'),
		url(/static/media/SegoeUI-SemiBold.3a911b2e.eot?#iefix) format('embedded-opentype'),
		url(/static/media/SegoeUI-SemiBold.30fd9798.woff) format('woff'),
		url(/static/media/SegoeUI-SemiBold.7e7e91c1.ttf) format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Segoe UI';
	src: url(/static/media/SegoeUI.afeddaf4.eot);
	src: local('Segoe UI'), local('SegoeUI'),
		url(/static/media/SegoeUI.afeddaf4.eot?#iefix) format('embedded-opentype'),
		url(/static/media/SegoeUI.7d57fcab.woff) format('woff'),
		url(/static/media/SegoeUI.b127acaf.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Segoe UI';
	src: url(/static/media/SegoeUI-Bold.d41fe674.eot);
	src: local('Segoe UI Bold'), local('SegoeUI-Bold'),
		url(/static/media/SegoeUI-Bold.d41fe674.eot?#iefix) format('embedded-opentype'),
		url(/static/media/SegoeUI-Bold.2621e35a.woff) format('woff'),
		url(/static/media/SegoeUI-Bold.58c5b6ad.ttf) format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Segoe UI';
	src: url(/static/media/SegoeUI-Italic.e764e7e9.eot);
	src: local('Segoe UI Italic'), local('SegoeUI-Italic'),
		url(/static/media/SegoeUI-Italic.e764e7e9.eot?#iefix) format('embedded-opentype'),
		url(/static/media/SegoeUI-Italic.b84c00b4.woff) format('woff'),
		url(/static/media/SegoeUI-Italic.036b8627.ttf) format('truetype');
	font-weight: normal;
	font-style: italic;
}

